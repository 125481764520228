const env = (key) => {
  if (window.env && window.env[key])
    return (window.env[key] || "").toString().trim();
  if (process.env && process.env[key])
    return (process.env[key] || "").toString().trim();
  return "";
};

export const setEnv = (key, value) => {
  if (window) {
    if (!window.env) {
      window.env = {};
    }
    window.env[key] = value;
  } else if (process) {
    if (!process.env) {
      process.env = {};
    }
    process.env[key] = value;
  }
};

export default env;
